import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link, graphql, navigate } from 'gatsby';
import Layout from "../components/layout/layout";
import ContactSuccess from "../components/contact-success";

import { Helmet } from 'react-helmet';

const Contact = ({ location }) => {
    const { register, handleSubmit, errors, getValues, formState: { isSubmitting } } = useForm({
        defaultValues: {
            company: location?.state?.data?.company ? location?.state?.data?.company : '',
            email: location?.state?.data?.email ? location?.state?.data?.email : '',
            name: location?.state?.data?.name ? location?.state?.data?.name : '',
            phone: location?.state?.data?.phone ? location?.state?.data?.phone : '',
            subject: '',
            message: '',
            autoReply: ''
        }
    });

    const [_showError, _setShowError] = useState("");
    const [_showSuccess, _setSuccess] = useState("");

    const onSubmit = async (data) => {
        try {
            await fetch('http://www.t-service.co.jp/api/email.php', {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            });

            _setSuccess((
                <ContactSuccess success="true">正常に送信されました。</ContactSuccess>
            ));
        }
        catch (error) {
            console.log(error);
             _setShowError((
                <ContactSuccess success="false">エラーが発生しました。</ContactSuccess>
            ));
        }
    };
    
    return (

        <Layout location="contact" metadata={ { title: "お問合わせ｜株式会社テストサービス" } }>
            { _showSuccess }
            <main className="page--contact page">
            <noscript>JavaScript を適切に有効にする必要があります</noscript>
            { _showError }
                <section className="section-gmap-canvas">
                    <iframe 
                        className="section-gmap-canvas__iframe section-jumbotron__background"
                        src="https://maps.google.com/maps?q=%E6%9D%B1%E4%BA%AC%E9%83%BD%E6%9D%BF%E6%A9%8B%E5%8C%BA%E5%A4%A7%E5%B1%B1%E9%87%91%E4%BA%95%E7%94%BA47-12&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=&amp;output=embed" 
                        scrolling="no" 
                        hl="jl">
                    </iframe>
                    <div className="section-gmap-canvas__background-overlay">
                    </div> 
                </section>
                <div className="section-contact-gap">
                </div>
                <section class="section-contact-main-content" id="content-contact">
                    <div class="container">
                        <div class="card">
                            <div class="card-content">
                                <h3 class="center-align">お問合わせ</h3><br />
                                <p class="center-align form-leading" id="adjust-font-size">兼　苦情受付窓口</p>
                            </div>
                            <div class="card-content">
                                <div class="row">
                                    <div class="col s12 center-align">
                                        <p class="form-leading">お問合せの内容によっては、ご返答までお時間を頂く場合がございます。<br />
                                            苦情の場合には、受理→対処→有効性確認→申立者への通知の手順で 対応させて頂きます。ご了承下さいませ。
                                        </p>  
                                    </div>
                                </div>
                                <form id="form" name="form" method="post" onSubmit={ handleSubmit(onSubmit) }>
                                    <div class="row">
                                        <div class="col m6 s12">
                                            <div class="label-area">
                                                <small>入力してください 会社名</small>
                                            </div>
                                            <div class="input-field">
                                                <i class="material-icons prefix red-text text-darken-1">business_center</i>
                                                <input 
                                                name="company" 
                                                id="company" 
                                                type="text" 
                                                ref={ register() } />
                                                <label className="active" for="company">会社名 </label>
                                            </div>
                                        </div>
                                        <div class="col m6 s12">
                                            <div class="label-area">
                                                <small>入力してください 担当者名</small>
                                            </div>
                                            <div class="input-field">
                                                <i class="material-icons prefix red-text text-darken-1">person</i>
                                                <input 
                                                    name="name" 
                                                    id="name" 
                                                    type="text"
                                                    ref={ register() } />
                                                <label className="active" for="name">名前</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col m6 s12">
                                            <div class="label-area">
                                                <small>入力してください メールアドレス<span>*</span></small>
                                            </div>
                                            <div class="input-field">
                                                <i class="material-icons prefix red-text text-darken-1">email</i>
                                                <input 
                                                    name="email" 
                                                    id="email" 
                                                    type="email" 
                                                    ref={ register({ required: true })}
                                                    required />
                                                <label className="active" for="email">メールアドレス</label>
                                            </div>
                                        </div>
                                        <div class="col m6 s12">
                                            <div class="label-area">
                                                <small>入力してください 電話番号<span>*</span></small>
                                            </div>
                                            <div class="input-field">
                                                <i class="material-icons prefix red-text text-darken-1">phone</i>
                                                <input 
                                                    name="phone" 
                                                    id="phone" 
                                                    type="tel" 
                                                    ref={ register({ required: true })}
                                                    required />
                                                <label className="active" for="phone">電話番号</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col s12">
                                            <div class="label-area">
                                                <small>入力してください 件名</small>
                                            </div>
                                            <div class="input-field">
                                                <i class="material-icons prefix red-text text-darken-1">message</i>
                                                <input 
                                                    name="subject" 
                                                    id="subject" 
                                                    type="text" 
                                                    ref={ register() } />
                                                <label className="active" for="subject">件名</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col s12">
                                            <div class="label-area">
                                                <small>入力してください メッセージ</small>
                                            </div>
                                            <div class="input-field">
                                                <i class="material-icons prefix red-text text-darken-1">more_horiz</i>
                                                <textarea 
                                                class="materialize-textarea" 
                                                name="message" 
                                                id="message"
                                                ref={ register() }></textarea>
                                                <label className="active" for="message">メッセージ</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-content">
                                        <div class="row">
                                            <div class="col s12 center-align">
                                                <p class="red-text">* は入力必須項目です。</p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col s12 center-align">
                                                <label>
                                                    <input 
                                                        name="autoReply" 
                                                        id="autoReply" 
                                                        value="1" 
                                                        type="checkbox" 
                                                        ref={ register() } />
                                                    <span>送信完了のメールを受け取る</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col s12 center-align">
                                                <input type="reset" disabled={ isSubmitting } value="リセット" class="btn white center" />
                                                <input type="submit" disabled={ isSubmitting } value="確 認" class="btn red center" />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="section-contact-gap">
                </div> 
            </main>
        </Layout>
    )
}

export default Contact;
